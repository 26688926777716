import service from '@/utils/request';

// getFileRoleList
export var getFileRoleList = function getFileRoleList(params) {
  return service({
    url: "/fileRole/getFileRoleList",
    method: 'get',
    params: params
  });
};

// getFileRoleByName
export var getFileRoleByName = function getFileRoleByName(params) {
  return service({
    url: "/fileRole/getFileRoleByName",
    method: 'get',
    params: params
  });
};

//创建 createFileRole
export var createFileRole = function createFileRole(data) {
  return service({
    url: "/fileRole/createFileRole",
    method: 'post',
    data: data
  });
};

//修改文件
export var updateFileRole = function updateFileRole(data) {
  return service({
    url: "/fileRole/updateFileRole",
    method: 'put',
    data: data
  });
};

// addFileRoleWithUser
export var addFileRoleWithUser = function addFileRoleWithUser(data) {
  return service({
    url: "/fileRole/addFileRoleWithUser",
    method: 'post',
    data: data
  });
};

//getUserByRoleId
export var getUserByRoleId = function getUserByRoleId(data) {
  return service({
    url: "/fileRole/getUserByRoleId",
    method: 'post',
    data: data
  });
};

//getUserNotInByRoleId
export var getUserNotInByRoleId = function getUserNotInByRoleId(data) {
  return service({
    url: "/fileRole/getUserNotInByRoleId",
    method: 'post',
    data: data
  });
};

// addFileRoleWithFolder
export var addFileRoleWithFolder = function addFileRoleWithFolder(data) {
  return service({
    url: "/fileRole/addFileRoleWithFolder",
    method: 'post',
    data: data
  });
};

// getFoldersByRoleId
export var getFoldersByRoleId = function getFoldersByRoleId(data) {
  return service({
    url: "/fileRole/getFoldersByRoleId",
    method: 'post',
    data: data
  });
};
export var deleteFileRole = function deleteFileRole(data) {
  return service({
    url: "/fileRole/deleteFileRole",
    method: 'delete',
    data: data
  });
};
export var batchDelFileRoleByIds = function batchDelFileRoleByIds(data) {
  return service({
    url: "/fileRole/deleteFileRoleByIds",
    method: 'delete',
    data: data
  });
};