var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-transfer", {
    attrs: {
      data: _vm.userTransferData,
      props: {
        key: "key",
        label: "label"
      },
      "filter-placeholder": "enter the key words",
      titles: ["User List", "Authorized Users"],
      filterable: "",
      "filter-method": _vm.filterMethod
    },
    model: {
      value: _vm.userTransferModel,
      callback: function callback($$v) {
        _vm.userTransferModel = $$v;
      },
      expression: "userTransferModel"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };